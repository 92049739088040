import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import {Router, NavigationEnd} from '@angular/router'; // import Router and NavigationEnd
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Gtag } from 'angular-gtag';

declare let ga;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private gtag: Gtag,
  ) {
    this.initializeApp();
    gtag.pageview();
  }

  ngOnInit(){
    
     // subscribe to router events and send page views to Google Analytics
     this.router.events.subscribe(event => {


      
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

        
        if ("ga" in window) {
          let tracker = ga.getAll();
          for (let i = 0; i<tracker.length; i++) {
              if (tracker[i]) {
                tracker[i].set('page', event.urlAfterRedirects);
                tracker[i].send('pageview');
              }
          }
      } 

    }
      

    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#222E50');
      this.statusBar.styleBlackOpaque();
      this.splashScreen.hide();
    });
  }
}
