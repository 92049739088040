import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  /* { path: '', redirectTo: 'home', pathMatch: 'full' }, */
  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  /* {
    path: 'list-produits',
    loadChildren: () => import('./pages/list-produits/list-produits.module').then( m => m.ListProduitsPageModule)
  },
  {
    path: 'fiche-produit/:id',
    loadChildren: () => import('./pages/fiche-produit/fiche-produit.module').then( m => m.FicheProduitPageModule)
  }, */
  {
    path: 'envoi-email/:id',
    loadChildren: () => import('./pages/envoi-email/envoi-email.module').then( m => m.EnvoiEmailPageModule)
  },
  {
    path: 'charte-graphique',
    loadChildren: () => import('./pages/charte-graphique/charte-graphique.module').then( m => m.CharteGraphiquePageModule)
  },
  /* {
    path: 'distance-orage',
    loadChildren: () => import('./pages/distance-orage/distance-orage.module').then( m => m.DistanceOragePageModule)
  },
  {
    path: 'accueil-orage',
    loadChildren: () => import('./pages/accueil-orage/accueil-orage.module').then( m => m.AccueilOragePageModule)
  },
  {
    path: 'instructions-orage',
    loadChildren: () => import('./pages/instructions-orage/instructions-orage.module').then( m => m.InstructionsOragePageModule)
  },
  {
    path: 'result-orage',
    loadChildren: () => import('./pages/result-orage/result-orage.module').then( m => m.ResultOragePageModule)
  }, 
  {
    path: 'questionnaire',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then( m => m.QuestionnairePageModule)
  }, */
  /* {
    path: 'result-recherche',
    loadChildren: () => import('./pages/result-recherche/result-recherche.module').then( m => m.ResultRecherchePageModule)
  }, */
  {
    path: 'qcm-home',
    loadChildren: () => import('./pages/qcm-home/qcm-home.module').then( m => m.QcmHomePageModule)
  },
  {
    path: 'informations-legales',
    loadChildren: () => import('./pages/informations-legales/informations-legales.module').then( m => m.InformationsLegalesPageModule)
  },
  {
    path: 'recherche',
    loadChildren: () => import('./pages/recherche/recherche.module').then( m => m.RecherchePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
